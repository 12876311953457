let selectors = {};
selectors.searchFileBtn = '.js-search-file';
selectors.searchInput = '.js-search-string';
selectors.searchFileBy = '.js-search-file-by';
selectors.selectedFileInstance = '.js-institution';
selectors.errorMsg = '.js-error-msg';
selectors.genericErrorMsg = '.js-generic-error';
selectors.searchFileContainer = '.js-search-file-container';
selectors.homePageSection = '.js-app-homepage';
selectors.showHearings = '.js-show-all-hearings';
selectors.trackFile = '.js-track-file';
selectors.fileDetails = '.js-file-details';
selectors.fileNumberInst = '.js-file-number-inst';
selectors.submitFileNotes = '.js-submit-file-notes';

$(document).ready(function () {
    if (window.currentRouteName === 'file.view') {
        tinymce.init({
            selector: '.js-file-notes',
            menubar: false,
            content_css: false,
            skin: false,
            setup: function(editor) {
                editor.on('change', function(e) {
                    editor.save();
                });
            }
        });
    }

    $(selectors.searchFileBtn).on('click', function (e) {
        e.preventDefault();
        let searchBy = $('input[name="search_by"]:checked').val();
        let formAction = window.searchFileUrl;
        if (searchBy === 'part-name') {
            $('#modal-show-search-file-info').modal('show');
        }
        searchFile(formAction, searchBy);
    });

    $('.js-close-modal').on('click', function () {
        $('.modal').modal('hide');
    })
});

$(document).on("click", ".js-show-all-hearings", function(e) {
    e.preventDefault();
    console.log('show-hearings');
    let fileKey = $(this).data('file-key');
    $('#modal-show-hearings-' + fileKey).modal('show');
});

$(document).on("click", ".js-file-number-inst", function(e) {
    e.preventDefault();
    let fileKey = $(this).data('file-key');
    let fileDetails = $('.js-file-details-' + fileKey);
    let fileObjectInfo = $('.js-file-object-info-' + fileKey);
    let showFileDetails = $('.js-show-file-details-'  + fileKey);

    if (fileDetails.hasClass('d-none')) {
        fileDetails.removeClass('d-none');
        fileObjectInfo.addClass('d-none');
        showFileDetails.html('<i class="fa fa-window-close" aria-hidden="true"></i>&nbsp; ' + ' Închide');
        $('.file-item-' + fileKey).addClass('file-item-open');
    } else {
        fileDetails.addClass('d-none');
        fileObjectInfo.removeClass('d-none');
        $(showFileDetails).html('<i class="fa fa-folder-open" aria-hidden="true"></i>&nbsp; ' + ' Deschide');
        $('.file-item-' + fileKey).removeClass('file-item-open');
    }

    //$(this).closest(selectors.fileDetails).removeClass('d-none').fadeOut(300);
});

$(document).on("click", ".js-show-file-note", function (e) {
    e.preventDefault();
    $('.js-add-edit-file-note').modal('show');
});

$(document).on("click", ".page-link", function (e) {
    e.preventDefault();
    let formAction = this.href;
    let searchBy = $('input[name="search_by"]:checked').val();
    searchFile(formAction, searchBy);
})

async function searchFile(formAction, searchBy) {
    try {
        $.ajaxSetup(getMyCsrfToken());
        $("div.wr-spanner").addClass("show");
        $("div.wr-overlay").addClass("show");
        $('.js-search-message').text('Rezultatele căutării urmează sa fie afișate...');

        const response = await $.ajax({
            url: formAction,
            type: 'GET',
            data: {
                'search_string': $(selectors.searchInput).val(),
                'search_by': searchBy,
                'institution': $(selectors.selectedFileInstance).val(),
                'action': 'search-file'
            },
            accepts: {
                text: "application/json"
            },
        });

        if (isEmpty(response)) {
            console.log('File not found!');
            $(selectors.searchFileContainer).find(selectors.errorMsg).removeClass('d-none');
            $(selectors.searchFileContainer).find(selectors.errorMsg).text('Dosarul nu a fost găsit!');
            $(selectors.errorMsg);
        } else {
            if (response.status === 'error') {
                $(selectors.searchFileContainer).find(selectors.errorMsg).removeClass('d-none');
                $(selectors.searchFileContainer).find(selectors.errorMsg).text(response.message);
                $(selectors.errorMsg);
            } else {
                $(selectors.homePageSection).remove();
                $('#content').find('.content-wrap').html(response.html);
            }
        }
    } catch (response) {
        if (response.status === 500) {
            $(selectors.genericErrorMsg).removeClass('d-none');
            if (response.responseJSON.message.length > 0) {
                $(selectors.genericErrorMsg).text(response.responseJSON.message);
            } else {
                $(selectors.genericErrorMsg).text(response.message);
            }
        } else {
            $(selectors.errorMsg).removeClass('d-none');
            showValidationErrors(response, $(selectors.searchFileContainer));
        }
    } finally {
        $('.js-search-message').text('');
        $('div.wr-spanner').removeClass('show');
        $('div.wr-overlay').removeClass('show');
    }
}
